$(document).ready(function () {
    function formatRepo(repo) {

        if (repo.loading)
            return repo.text;

        var markup = "<a href='#' style='display:block;'>" + repo.title + "</a>";

        return markup;
    }

    function formatRepoSelection(repo) {

        return repo.slug;
    }
    search_autofill('search-front','Search for musical term or composer');
    search_autofill('search-header','Search...');
    function search_autofill(classname,placeholder){
    var CSRF_TOKEN = $('meta[name="csrf-token"]').attr('content');
    $("."+classname).select2({
        multiple: true,
        maximumSelectionSize: 1,
        placeholder: placeholder,
        ajax: {
            url: "/search/",
            method: 'get',
            dataType: 'json',
            delay: 250,
            data: function (params) {
                return {
                    id: params.term, // search term
                    page: params.page,
                    _token: CSRF_TOKEN
                };
            },
            processResults: function (data,params) {
                // parse the results into the format expected by Select2
                // since we are using custom formatting functions we do not need to
                // alter the remote JSON data, except to indicate that infinite
                // scrolling can be used
                params.page = params.page || 1;
                console.log(data);
                return {
                    results: data.items,
                    pagination: {
                    more: (params.page * 30) < data.total_count
                  }
                };
            },
            cache: true
        },
        escapeMarkup: function (markup) {
            return markup;
        }, // let our custom formatter work
        minimumInputLength: 1,
        templateResult: formatRepo, // omitted for brevity, see the source of this page
        templateSelection: formatRepoSelection // omitted for brevity, see the source of this page
    });

    $("."+classname).on("change", function (e) {
       var slug = $(this).val();
       var title = $(this).select2('data')[0]['slug'];
        if (classname !='search-header') {
        $('.select2-selection__rendered').hide();
        }
        window.location = '/terms/'+slug+'-'+title;
    });
    }
});
